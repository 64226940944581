<template>
  <v-dialog v-model="visible" persistent max-width="600">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        {{ duration ? "Tidak berada di tempat kerja" : "Ubah Jadwal" }}
      </v-card-text>
      <v-divider class="mb-4" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Tanggal</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${form.skip_date}`"
              disabled
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >NIP/Nama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${form.NIP} - ${form.nama}`"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="duration">
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Jenis Masukan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-select
                v-model="form.type"
                :items="types"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="form.type == 'Rentan Waktu'">
            <v-col cols="6" class="py-1">
              <v-text-field
                v-model="form.skip_start"
                label="Durasi Mulai"
                outlined
                type="time"
                step="1"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-1">
              <v-text-field
                v-model="form.skip_end"
                label="Durasi Akhir"
                outlined
                type="time"
                step="1"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Total Durasi (menit)</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                :readonly="form.type == 'Rentan Waktu'"
                v-model="form.skip_durasi"
                type="number"
                outlined
                dense
                :error="errorDuration != ''"
                :error-messages="errorDuration"
                :rules="[v => !!v || 'Total durasi harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >List Skip</v-subheader
            >
            <v-data-table
              id="table-custom-pegawai"
              class="mt-3"
              :headers="skipHeader"
              :items="skipAttendances"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  x-small
                  color="#FBB005"
                  class="mr-2 my-1 white--text"
                  @click="handleDelete(item)"
                  >Hapus</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Tambahkan</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";

export default {
  props: {
    duration: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      formLoading: false,
      errorDuration: "",
      form: {
        NIP: null,
        nama: null,
        type: "Durasi",
        skip_date: null,
        skip_durasi: null,
        skip_start: null,
        skip_end: null
      },
      types: ["Rentan Waktu", "Durasi"],
      skipAttendances: [],
      skipHeader: [
        {
          text: "Mulai",
          value: "skip_start",
          sortable: false,
          align: "center"
        },
        {
          text: "Akhir",
          value: "skip_end",
          sortable: false,
          align: "center"
        },
        {
          text: "Durasi",
          value: "skip_durasi",
          sortable: false,
          align: "center"
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    durationRange() {
      if (this.form.skip_start && this.form.skip_end) {
        let start = this.form.skip_start.split(":");
        let skip_start = parseInt(start[0]) * 60 + parseInt(start[1]);
        let end = this.form.skip_end.split(":");
        let skip_end = parseInt(end[0]) * 60 + parseInt(end[1]);

        this.form.skip_durasi = skip_end - skip_start;
        if (this.form.skip_durasi < 0) {
          this.errorDuration = "Minimal durasi 0";
        } else {
          this.errorDuration = "";
        }
      } else {
        this.errorDuration = "Durasi mulai dan durasi akhir harus diisi";
      }
    },
    visible(val) {
      if (!val) {
        this.form.type = "Durasi";
      }
    }
  },
  computed: {
    durationRange() {
      return [this.form.skip_start, this.form.skip_end].join();
    }
  },
  methods: {
    convertMinutes(minute) {
      let hours = Math.floor(minute / 60);
      let minutes = minute % 60;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return hours + ":" + minutes;
    },
    edit(item) {
      this.form.NIP = item.NIP;
      this.form.nama = item.nama;
      this.form.skip_date = item.skip_date;
      this.form.skip_durasi = item.skip_durasi;
      this.form.skip_start = item.skip_start;
      this.form.skip_end = item.skip_end;
      this.visible = true;
      this.listSkip();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saveSkip({
          NIP: this.form.NIP,
          skip_date: this.form.skip_date,
          skip_durasi: this.convertMinutes(this.form.skip_durasi),
          skip_start: this.form.skip_start,
          skip_end: this.form.skip_end
        });
      }
    },
    handleDelete(item) {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteSkip({
              skip_id: item.skip_id
            });
          }
        }
      });
    },
    // Service
    async listSkip() {
      try {
        this.loading = true;
        await AttendanceService.listSkip({
          NIP: this.form.NIP,
          skip_date: this.form.skip_date
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.skipAttendances = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveSkip(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.saveSkip(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.$emit("on-refresh");
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteSkip(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.deleteSkip(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.listSkip();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
